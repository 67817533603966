import React, { useEffect, useRef, useState } from 'react'
import { Field } from 'formik'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { optionsTransform } from '../../helpers/lang'
import hintIcon from '../../static/img/svg/hint-icon.svg'
import { mergeRefs } from '../../helpers/globals'

export default function Select({ name, onChange = () => null, label = null, required, inputRef,
    className, hint, options, defValue = options[0].name, error, filter = false }) {
    const [show, setShow] = useState(false)
    const { i18n, t } = useTranslation('common')
    const opts = optionsTransform(options, i18n.language)
    const ref = useRef(null)
    const [filtered, setFiltered] = useState(opts)

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setShow(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    })

    if (!opts.filter) return (<div>{JSON.stringify(opts, null, 2)}</div>)

    return (
        <div className={className} ref={mergeRefs(ref, inputRef)}>
            <label className={cn({ hint__label: hint, 'label-valid': !error }, 'select__label')}>
                {label}
                { required ? <span style={{ color: 'red', paddingLeft: '4px' }}>*</span> : null }
                {
                    hint
                        ? (
                            <span className="hint__icon">
                                <img src={hintIcon} alt="" width="16" height="16" />
                                <span className="hint__content">
                                    {hint}
                                </span>
                            </span>
                        ) : null
                }
                {
                    error
                        ? (
                            <div className="input_error">
                                <span
                                    className="input_error-text">
                                    {t('wrongInput')}
                                </span>
                            </div>
                        ) : null
                }
            </label>

            <Field name={name}>
                {
                    ({ form, field }) => {
                        let text = defValue
                        if (field.value || field.value === 0) {
                            const selected = opts.filter((item) => (item.value === field.value))
                            text = selected[0] ? selected[0].label || selected[0].name : defValue
                        }

                        return (
                            <div>
                                <div className={cn('select__current',
                                    { select__current_opened: show })} onClick={() => setShow(!show)}>
                                    {text}
                                </div>

                                <div className={cn('select__list',
                                    { select__list_open: show })}>
                                    {filter && (
                                        <input onClick={(e) => e.stopPropagation()}
                                            onChange={(e) => {
                                                const search = e.target.value.toLowerCase()
                                                setFiltered(opts.filter((item) => {
                                                    if (item.name) {
                                                        return item.name.toLowerCase().indexOf(search) !== -1
                                                    }
                                                    return item.label.toLowerCase().indexOf(search) !== -1
                                                }))
                                            }}
                                            className="select__search select__search-icon" type="search" />
                                    )}
                                    {filtered.map((item) => (
                                        <div
                                            key={`${name}-${item.value}`}
                                            className="select__item"
                                            onClick={() => {
                                                form.setFieldValue(name, item.value).then(() => onChange(item.value))
                                            }}>
                                            {item.name || item.label}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    }
                }
            </Field>
        </div>
    )
}
