import React, { useEffect, useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Input from './base/Input'
import TextArea from './base/TextArea'
import FileInput from './base/FileInput'
import { usePostRequest } from '../hooks/request'
import { REST } from '../utils/urls'
import InputMask from './base/InputMask'
import Loader from './base/Loader'

const validate = ({ fullName, policyNumber, note, phone, email }) => {
    const errors = {}

    if (!fullName || !fullName.match(/^[а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷa-zA-Z ]{2,50} [а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷa-zA-Z ]{3,200}$/)) errors.fullName = true
    if (!policyNumber || !policyNumber.match(/^[0-9]{10}$/)) errors.policyNumber = true
    if (!note || !note.match(/^[а-яА-ЯёËҒғӢӣҚқӮӯҲҳҶҷa-zA-Z0-9();'" -,.]{3,999}$/)) errors.note = true
    if (!email || !email.length > 50 || (!email.match(/^[\w-.]+@[\w-]+\.[a-z]{2,}$/i))) errors.email = true
    if (!phone || !phone.match(/^[()0-9- ]{12,17}/)) errors.phone = true

    return errors
}

export default function RegisterLosses() {
    const { state } = useLocation()
    const initialValues = {
        fullName: '',
        policyNumber: state ? state.policyNumber : '',
        note: '',
        phone: '',
        email: '',
        channel: 'front',
    }
    const [files, setFiles] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [onLoad, setOnLoad] = useState(false)
    const { push } = useHistory()

    const addFile = ({ target }) => {
        const filesArray = [...files]
        Array.from(target.files).some((file) => {
            if (filesArray.length >= 10) return true
            filesArray.push(file)
            return false
        })
        setFiles(filesArray)
    }

    const delFile = (i) => {
        const filesArray = [...files].filter((_, index) => index !== i)
        setFiles(filesArray)
    }

    const { t } = useTranslation('losses')
    const saveClaim = usePostRequest({ url: REST.CLAIM.SEND })
    const policyCheck = usePostRequest({ url: REST.POLICY_CHECK })
    const addClaimFiles = usePostRequest({ url: REST.CLAIM.FILES })
    const formik = useFormik({
        initialValues,
        validate,
        onSubmit: sendData,
    })
    useEffect(() => {
        formik.setFieldValue('policyNumber', formik.values.policyNumber.replace(/[^0-9]/, ''))
    }, [formik.values.policyNumber])

    async function sendData(data, actions) {
        setOnLoad(true)
        const { error: checkError } = await policyCheck.request({ data: { policyNumber: data.policyNumber } })

        if (checkError && checkError.status === 422) {
            actions.setErrors({ policyNumber: true })
            setOnLoad(false)
            return
        }

        const { success, response } = await saveClaim.request({ data })
        if (success) {
            const url = REST.CLAIM.FILES.replace('{claim_id}', response.id)
            if (files.length !== 0) {
                const formData = new FormData()
                files.forEach((item) => {
                    formData.append('files', item)
                })
                await addClaimFiles.request({ url, data: formData })
            }
            if (success) {
                setLoaded(true)
                actions.resetForm(
                    { values: { ...response, country: data.country } },
                )
            }
        }
        setOnLoad(false)
    }

    if (loaded) {
        return (
            <div className="registration-losses-success">
                <div className="registration-losses__container">
                    <h1 className="registration-losses__title_success">
                        {t('registred')}
                    </h1>
                    <div className="button__block">
                        <button className="registration-losses__button_accept"
                            onClick={() => { push('/') }}
                            type="submit">
                            {t('ok')}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="registration-losses">
            <div className="registration-losses__container">
                <h1 className="registration-losses__title">
                    {t('registerEvent')}
                </h1>
                <FormikProvider value={formik}>
                    <Form>
                        <div className="registration-losses__form-row">
                            <Input name="fullName" label={t('fullNamePH')}
                                placeholder={t('fullName')} error={formik.errors.fullName} required
                            />
                            <Input name="policyNumber" label={t('policyNumber')} required
                                placeholder={t('policyNumberPH')} error={formik.errors.policyNumber}
                            />
                        </div>
                        <div className="registration-losses__form-row">
                            <Input
                                name="email" required
                                label={t('email')}
                                error={formik.errors.email}
                                placeholder="mail@mail.ru" />
                            <InputMask name="phone" error={formik.errors.phone} required
                                label={t('phone')} placeholder="(992) 00-000-0000" />
                        </div>
                        <div className="input losses-notes">
                            <TextArea label={t('notePH')}
                                placeholder={t('note')} required
                                name="note" error={formik.errors.note} />
                        </div>
                        <FileInput files={files} setFiles={addFile} delFile={delFile} />
                        {
                            onLoad
                                ? (
                                    <div style={{ marginBottom: '10em' }}>
                                        <Loader />
                                    </div>
                                )
                                : (
                                    <button className="registration-losses__button" type="submit" style={{ marginBottom: '8em' }}>
                                        {t('submit')}
                                    </button>
                                )
                        }
                    </Form>
                </FormikProvider>
            </div>
        </div>
    )
}
