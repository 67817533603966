/* eslint-disable max-len */

export default {
    header: 'Бақайдгирии суғурта',
    homeHeader: 'Ҳангоми рондан ором бошед',
    'headStepnot-step': 'Маълумот',
    headStep1: 'Маълумот',
    headStep2: 'Барномаҳо',
    headStep3: 'Имконот',
    headStep4: 'Ороиш',
    headStep5: 'Пардохт',
    carMark: 'Бренд',
    carModel: 'Моделҳо',
    carBodyType: 'Намуди бадан',
    carIssueYear: 'Соли нашр',
    carBodyTypeLabel: {
        0: 'Седан',
        1: 'Хэтчбек',
        2: 'Вагон ҳои истгоҳӣ',
        3: 'SUV',
        4: 'Кроссовер',
        5: 'Гирифтан',
        6: 'микроавтобус',
        7: 'Ван',
        8: 'конвертатсияшаванда',
        9: 'Кубо',
    },
    policeSumInsured: 'Маблаги сугурта',
    calculateInstallments: 'Ҳисоб кардани қисмҳо',
    insuranceCost: 'Баҳодиҳӣ:',
    openInfoPopup: 'Ба сиёсат чӣ дохил карда шудааст?',
    inInstallments: 'Ба қисмҳо',
    toCar: 'дар як мошин',
    toCarYear: 'синну сол',
    onMonth: 'сомонӣ дар як моҳ',
    promoCode: 'рамзи таблиғ',
    errorCoupon: 'Хатогӣ ҳангоми ворид кардани рамзи таблиғот',
    coupon: 'Рамзи таблиғотро ворид кунед',
    nextStep: 'Минбаъд',
    optionsMore: 'Имкониятҳои иловагӣ',
    compensation: 'Маблағи ҷуброн',
    damageOption: 'Зарар',
    tfapOption: 'PDTL',
    stealingOption: 'Дуздӣ',
    noTraficPoliceOption: 'Бе шаҳодатномаи пулиси роҳ',
    accSumAssured: 'Садама',
    beginDate: 'санаи оғоз',
    automobile: 'Мошин',
    insured: 'Соҳиби полис',
    holderFullName: 'Номи пурра',
    holderFullNamePH: 'Алексей Петрович Иванов',
    holderAddress: 'Адреси истикомат',
    holderAddressPH: 'Адреси истикомат',
    holderPhone: 'Телефон',
    holderEmail: 'Почтаи электронӣ',
    drivers: 'Ронандагон',
    multidrive: 'Multidrive',
    copyHolderData: 'Маълумотро дар бораи суғурташуда нусхабардорӣ кунед',
    addDriver: 'Илова кардани ронанда',
    deleteDriver: 'Драйверро нест кунед',
    vin: 'VIN',
    color: 'Ранги',
    gosNomer: 'Рақами давлатӣ',
    registrSvidet: 'Шаҳодатномаи бақайдгирӣ',
    holderDocType: 'Навъи ҳуҷҷат',
    holderDocSeries: 'Силсилаи шиносномаҳо',
    holderDocNumber: 'ID шиноснома',
    driverDocSeries: 'Силсилаи VU',
    driverDocNumber: 'рақами VU',
    driverIssueDate: 'Санаи додани',
    holderIssueDate: 'Санаи додани паспорт',
    citizenship: 'Шаҳрвандӣ',
    holderVuSeries: 'Силсилаи VU',
    holderVuNumber: 'рақами VU',
    holderVuIssueDate: 'Санаи нашри VU',
    holderBirthday: 'Таърихи таввалуд',
    holderGender: 'Ошёна',
    changeInfo: 'Тағйир',
    beginDateIssued: 'Санаи оғози суғурта',
    durationIssued: '{{duration}} рӯз',
    holderAgree: 'Барои бақайдгирӣ',
    driving_interval: '(0)[{{count}} сол]; (1)[{{count}} сол]; (2-4)[{{count}} сол]; (5-inf)[{{count}} сол]',
    additionalOptions: 'Имкониятҳои интихобшуда',
    reqFieldsHead: 'Пур карда шудан',
    reqFieldsText: 'майдонҳои зарурӣ',
    damageOptionHint: 'BIMA хисороти дар натиҷаи осеб дидан ё нобуд шудани Мошин, қисмҳои алоҳидаи он, васлҳо, васлкунакҳо дар натиҷаи сӯхтор, таркиш, падидаҳои табиӣ, афтодан ё фурӯ рафтани ашёи бегона ё осеб дидани ҳайвонотро ҷуброн мекунад.',
    stealingOptionHint: 'BIMA хароҷотро дар сурати вайрон шудан ё гум шудани мошин дар натиҷаи дуздӣ (дуздӣ, ғорат, ғорат) ҷуброн мекунад.',
    tfapOptionHint: 'BIMA хароҷоти ҳангоми осеб дидани Мошин ё нобудшавии Автомобил дар натиҷаи амалҳои ғайриқонунии шахсони сеюм ва хатари гум шудани қисмҳо, васлаҳо, васлҳои мошин дар натиҷаи дуздии онҳо ҷуброн мекунад.',
    noTraficPoliceOptionHint: 'BIMA хароҷотеро, ки барои барқарор кардани мошини дар садама осебдида бидуни шаҳодатномаи пулиси роҳ зарур аст, ҷуброн мекунад.',
    accSumAssuredHint: 'BIMA зарари моддии дар натиҷаи ҷароҳат, маъюбии доимӣ (маъюбӣ) ё марги мусофирон ва/ё ронандаи воситаи нақлиёти суғурташударо ҷуброн мекунад.',
    annually: 'annually',
    monthly: 'monthly',
    taxi: 'Таксиро суғурта карда наметавонад',
    SCI_P1name: 'Стандарт',
    SCI_P2name: 'Комфорт',
    SCI_P3name: 'Премиум',
    SCI_P4name: 'Инфиродӣ',
    SCI_P1description: 'Вазъи ҳаракати нақлиёт ҳамеша тағйир меёбад, ҳатто агар шумо ронандаи ботаҷриба бошед! Дар доираи барномаи «Стандарт» барои таъмири мошини шахси дигар, ки бо айби шумо дар садама зарар дидааст, пардохт намекунед. Ҳама хароҷотро суғуртаи шумо ҷуброн мекунад.',
    SCI_P2description: 'Ҳодисаҳои ғайричашмдошт дар роҳ ҳамеша рӯй медиҳанд! Барномаи «Комфорт» як «болишти» молиявӣ ҳангоми садама бо айби шумост, ки барқароршавии автомобили шумо ва автомобили осебдидаро таъмин мекунад.',
    SCI_P3description: 'Ҳодисаҳои пешгӯинашаванда дар роҳ маъмуланд! Дар доираи барномаи «Премиум» новобаста аз он, ки ҳодиса бо айби кӣ рух додааст —шумо ё ронандаи дигар, таъмири мошинҳо аз ҷониби ширкати суғуртавӣ ҷуброн карда мешавад.',
    SCI_P4description: 'Дар роҳ ҳар як иштирокчӣ ба хавфу хатар дучор мешавад — ронандагон, мусофирон, пиёдагардон! Барномаи "Инфиродӣ" ба шумо имкон медиҳад, ки барномаи суғуртаи шахсии худро бо интихоби тамоми хизматрасониҳои зарурӣ ё имконоти мувофиқ эҷод кунед. Барномаи мукаммал суғуртаи ҳамаи иштирокчиёни садама ва ҷуброни барқарорсозии воситаҳои нақлиёти вайроншударо бо истифода аз полиси суғуртавӣ таъмин мекунад.',
    categories: 'Категории',
    categories_name: [
        'Шумо бояд ҳадди аққал 21 сол дошта бошед.',
        'Таҷрибаи ҳадди ақали ронандагӣ бояд на камтар аз 1 сол бошад.',
        'Мо автомобилҳоеро, ки соли онҳо аз 20 сол зиёд нестанд, суғурта мекунем.',
        'Мо такси ва шахсони ҳуқуқиро суғурта намекунем.',
        'Ҳар як барномаи суғурта як пардохтро пешбинӣ мекунад.',
        'Пас аз пардохти аввал, полис танҳо барои барномае, ки пардохт барои он амалӣ шудааст, эътибори худро қатъ мекунад.<br>Полис тибқи дигар барномаҳои суғурта эътибор дорад.',
    ],
    driverSeriesNumber: 'Силсила ва рақам',
    driverPeriod: 'Таҷрибаи ронандагӣ',
    noVin: 'Агар VIN набошад, танҳо майдони Рақами баданро пур кунед.',
    ECI: 'Барномаро интихоб кунед',
    ECI_P1: 'Каско пурра',
    ECI_P2: 'Қисман каско',
    ECI_P3: 'Ғайриқонунӣ ҳай карда бурдан + Талафи пурра',
    ECI_P1_hint: 'Опсияҳо: «ҲРН бо айби ронанда», «ҲРН бо айби шахсони сеюм», «Нав ба ҷойи кӯҳна», «Ҷуброни хароҷотҳо», «Бе маълумотномаҳо»',
    ECI_P2_hint: 'Опсияҳо: «ҲРН бо айби шахси 3-юм», «Нав ба ҷойи кӯҳна», «Баҳодиҳии Суғуртакунанда», «Бе маълумотнома»',
    ECI_P3_hint: 'Опсияҳо: «ҲРН бо айби ронанда», «ҲРН бо айби шахси 3-юм», «Кӯҳна ба ҷойи кӯҳна» Агар ВН зиёда аз 20 сол, аммо камтар аз 30 сол дошта бошад.',
    crSumAssured: 'Масъулияти гражданй',
    incidentSumAssured: 'Ҳодисаи нохуш',
    crSumAssuredHint: 'Масъулияти гражданӣ ӯҳдадорӣ оиди ҷуброн намудани зарари бо айби Суғурташаванда ба шахсони сеюм расонидашуда мебошад',
    incidentSumAssuredHint: 'Ҳодисаи нохуш – ҳҳодисаи ногаҳонӣ, кӯтоҳмуддат, ғайричашмдошт, беғаразонаи беруна, ки хусусият, вақт ва ҷойи он метавонад яқинан муайян гардад ва он ба зарари ҷисмонӣ ё вайроншавии функсияҳои организм ё фавти шахси Суғурташуда оварда расонидааст, ки он натиҷаи беморӣ намебошад ва дар муддати муҳлати суғурта новобаста аз иродаи Суғурташуда ва/ё Суғурташаванда ва/ё Фоидагиранда ба вуқӯъ омадааст',
    compensation0: 'Ҷуброни хароҷот',
    compensation0Hint: 'Суғурташаванда ВН-и зарардидаро таъмир мекунад. Маблағи ҷуброн дар асоси ҳуҷҷатҳои пешниҳодшуда бо назардошти шартҳои шартномаи суғурта муайян карда мешавад. Пардохт аз ҷониби Суғуртакунанда ба фоидаи Суғурташаванда ё Фоидагиранда анҷом дода мешавад',
    compensation1: 'Экспертизаи мустақил',
    compensation1Hint: 'Ширкати суғуртавӣ барои ҳисоб кардани арзиши корҳои таъмирии воситаи нақлиёти зарардида экспертизаи мустақил мегузаронад. Бо розигии Суғуртакунанда ё бо ташаббуси ӯ экспертиза гузаронида мешавад, ки натиҷаи он маблағи ҷуброни суғуртавиро муайян мекунад. Ин маблағ хароҷоти экспертизаро дар бар мегирад ва мувофиқи шартҳои шартномаи суғурта ва Шартҳои полис муқаррар карда мешавад.',
    compensation2: 'Баҳодиҳии Суғуртакунанда',
    compensation2Hint: 'Арзиши корҳои таъмиру барқарорсозии ВН-и зарардида аз ҷониби мутахассисони Суғуртакунанда ҳисоб карда мешавад. Маблағи ҷуброни суғуртавӣ дар асоси натиҷаҳои баҳогузории зикршуда бо назардошти шартҳои шартномаи суғурта ва Шартҳои полис муайян карда мешавад. Пардохти маблағ (ҷуброни суғуртавӣ) аз ҷониби Суғуртакунанда (бо пули нақд ва/ё бо роҳи ғайринақдӣ) ба фоидаи Суғурташаванда ё Фоидагиранда амалӣ карда мешавад',
    compensation3: 'Нуқтаи хизматрасонии тахникии автомобилии Суғуртакунанда',
    compensation3Hint: 'Ташкили корҳои таъмиру барқарорсозии ВН-и зарардида аз ҷониби Суғуртакунанда дар НХТА, ки бо он муносибатҳои шартномавӣ дорад, сурат мегирад. Маблағ (ҷуброни суғуртавӣ) аз чониби Сугуртакунанда (бо пули нақд ва/ё бо роҳи гайринадди) ба фоидаи НХТА, ки таъмири ВН-и суғурташударо анҷом додааст (анҷом дода истодааст ё анҷом медиҳад) пардохт карда мешавад',
    programsMore: 'Барномаҳои иловагӣ',
    accidentAnyOption: 'ҲРН бо айби ронанда',
    accidentAnyOptionHint: 'Ҳодисаи роҳу нақлиёт, ки бо айби ронандаи воситаи нақлиёти суғурташуда ба миён омадааст, (пахш кардан, бархурдан ва ғ.)',
    accident3tpOption: 'ҲРН танҳо бо айби шахсони сеюм',
    accident3tpOptionHint: 'ҲРН бо иштироки ВН-и суғурташуда дар натиҷаи пахш кардан, чаппа шудан, бархурдан ва дигар амалҳои шахсони сеюм, аз ҷумла дар таваққуфгоҳ зарар дидан аз мошинҳои дигар',
    oldNewOption: 'Нав ба ҷойи кӯҳна',
    oldNewOptionHint: 'Қисмҳои эҳтиётӣ ва масолеҳе, ки ҳангоми таъмири ВН-и зарардида иваз мешаванд, бе назардошти фарсудашавӣ, яъне дар ҳаҷми зарари воқеӣ ҷуброн карда мешавад',
    noTraficPoliceSumAssured: 'Бе иштироки БДА',
    noTraficPoliceHint: 'Баррасии ҳодисаи суғуртавӣ бе иштироки БДА то: 1000 сомонӣ',
    damageCarWashSumAssured: 'Зарар дар нуқтаи шустушӯи мошинҳо',
    damageCarWashHint: 'Шумо ҳуқуқ доред, ки то ҳадди муайян (то 1000 сомонӣ) ҷуброни суғуртавӣ бигиред, агар кормандони нуқтаи шустушӯи мошинҳо амалҳои хилофи қонун содир карда бошанд, бе муроҷиат ба мақомоти махсус.',
    greenCard: 'Корти сабз',
    greenCardHint: 'Васеъшавии ҳудуди суғуртаи ВН ба кишварҳои дигар',
    greenCardUzbOption: 'Ӯзбекистон',
    greenCardKazOption: 'Қазоқистон',
    greenCardKgzOption: 'Қирғизистон',
    InsuranceAreaExpansion: 'Вусъат додани қаламрави суғурта',
    autoText: 'Мошин дар рӯйхат нест',
    amountCompensation: 'Маблағи ҷуброн',
}
